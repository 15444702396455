import { type AnyAction, type Middleware } from "@reduxjs/toolkit";

import { type ResponseMessageDto } from "reducers/responses/types";
import { UserAnalyticsService } from "services/userAnalytics";

enum UserAnalyticsEvent {
  USER_CREATED = "USER_CREATED",
  RESPONSE_GREETING_UPDATED = "RESPONSE_GREETING_UPDATED",
  RESPONSE_HANDOFF_CREATED = "RESPONSE_HANDOFF_CREATED",
  RESPONSE_HANDOFF_UPDATED = "RESPONSE_HANDOFF_UPDATED",
  CHANNEL_EMAIL_CREATED = "CHANNEL_EMAIL_CREATED",
}

const handleUserCreatedEvent = (action: AnyAction) => {
  if (action.type !== "CREATE_ONE_USER_SUCCESS") {
    return;
  }

  const { user } = action.data;
  // eslint-disable-next-line @typescript-eslint/naming-convention,@typescript-eslint/no-unused-vars
  const { email, name, role, time_zone } = user;

  UserAnalyticsService.capture(UserAnalyticsEvent.USER_CREATED, {
    // TODO: Waiting on https://adasupport.atlassian.net/browse/GRO-366 before we can send PII
    // email,
    // name,
    role,
    time_zone,
  });
};

const handleResponseHandoffSavedEvent = (action: AnyAction) => {
  if (action.type !== "SAVE_BOT_CONTENT_SUCCESS") {
    return;
  }

  /*
   * While it looks like we always receive this type, we can't confirm it 100%. Instead of creating arbitrary types
   * to try to appease the TS linter, we will use this and monitor sentry for errors. Each event handler is wrapped
   * in a try/catch regardless to ensure no one failed call blocks the rest of them or the entire middleware chain
   * */
  const handoff = Object.values(action.responses as ResponseMessageDto[]).find(
    (response) => response._type === "handoff",
  );

  if (!handoff) {
    return;
  }

  const { handle, description, created, updated } = handoff;
  const eventData = {
    handle,
    description,
  };
  const isUpdate = created !== updated;
  const event = isUpdate
    ? UserAnalyticsEvent.RESPONSE_HANDOFF_UPDATED
    : UserAnalyticsEvent.RESPONSE_HANDOFF_CREATED;

  UserAnalyticsService.capture(event, eventData);
};

const handleResponseGreetingUpdatedEvent = (action: AnyAction) => {
  if (action.type !== "SAVE_BOT_CONTENT_SUCCESS") {
    return;
  }

  /*
   * While it looks like we always receive this type, we can't confirm it 100%. Instead of creating arbitrary types
   * to try to appease the TS linter, we will use this and monitor sentry for errors. Each event handler is wrapped
   * in a try/catch regardless to ensure no one failed call blocks the rest of them or the entire middleware chain
   * */
  const greeting = Object.values(action.responses as ResponseMessageDto[]).find(
    (response) => response._type === "greeting",
  );

  if (!greeting) {
    return;
  }

  const { handle, description } = greeting;
  UserAnalyticsService.capture(UserAnalyticsEvent.RESPONSE_GREETING_UPDATED, {
    handle,
    description,
  });
};

const handleChannelEmailCreatedEvent = (action: AnyAction) => {
  if (action.type !== "api/executeMutation/fulfilled") {
    return;
  }

  const endpointName = action.meta?.arg?.endpointName;

  if (endpointName !== "createEmailPlatform") {
    return;
  }

  UserAnalyticsService.capture(UserAnalyticsEvent.CHANNEL_EMAIL_CREATED);
};

export const captureAnalyticsEvent: Middleware = () => (next) => (action) => {
  const eventHandlers = [
    handleUserCreatedEvent,
    handleResponseGreetingUpdatedEvent,
    handleResponseHandoffSavedEvent,
    handleChannelEmailCreatedEvent,
  ];

  // Separate try-catch for each event handler to prevent one failing from stopping the rest
  eventHandlers.forEach((handler) => {
    try {
      handler(action);
    } catch (e) {
      console.error("Analytics event capture failed", e, action?.type);
    }
  });

  return next(action);
};
