import qs from "qs";

import { adaAPI } from "services/api";
import { type JSONData } from "services/helpers";
import { type Variable } from "services/variables";
import { WebActionCharacterLimits } from "services/webActions/constants";

import { type InferredVariable, type WebAction } from "./api";

const getVariableIds = (str: string) =>
  str ? [...str.matchAll(/\{(\w+)\|[^}]*}/g)].map((match) => match[1]) : [];

export const getReferencedVariables = (
  webAction: Omit<WebAction, "_id">,
  variables: Variable[],
): Variable[] => {
  const variableIds = [
    ...getVariableIds(webAction.url),
    ...getVariableIds(webAction.request_body),
    ...webAction.headers.flatMap((h) => getVariableIds(h.value)),
  ];

  return variableIds
    .map((id) => variables.find((v) => v._id === id))
    .filter(
      (variable, index, self) =>
        // Remove duplicates
        self.findIndex((v) => v?._id === variable?._id) === index,
    )
    .filter(Boolean) as Variable[];
};

const getInferredVariableIds = (str: string) =>
  str ? [...str.matchAll(/\{\{(\w+)}}/g)].map((match) => match[1]) : [];

export const getReferencedInferredVariables = (
  webAction: Omit<WebAction, "_id">,
): InferredVariable[] => {
  const variableIds = [
    ...getInferredVariableIds(webAction.url),
    ...getInferredVariableIds(webAction.request_body),
    ...webAction.headers.flatMap((h) => getInferredVariableIds(h.value)),
  ];

  return webAction.inputs.filter((input) =>
    variableIds.includes(input.name),
  ) as InferredVariable[];
};

export const isIdentifier = (str: string) => {
  // Check if the string is not empty and starts with a letter, underscore, or dollar sign
  if (!str || !/^[a-zA-Z_$]/.test(str)) {
    return false;
  }

  // Check if the rest of the string contains only letters, digits, underscores, and dollar signs
  return /^[a-zA-Z0-9_$]*$/.test(str);
};

/** Validates input name and returns a validation message if invalid. */
export const getWebActionInputNameValidationMessage = (
  input: WebAction["inputs"][0],
): string | null => {
  if (!isIdentifier(input.name)) {
    return "Must be alphanumeric, no spaces, and start with a letter.";
  }

  if (input.name.length > WebActionCharacterLimits.inputName) {
    return "Name is too long.";
  }

  return null;
};

export const replaceVariablesWithValues = <
  T extends WebAction | Omit<WebAction, "_id">,
>(
  webAction: T,
  testValues: Record<string, string>,
): T => {
  const { url, headers, request_body: requestBody } = webAction;
  const variableRegex = /\{(\w{24})\|((?:[^{}]|\{[^{}]*})*)}/i;

  const replacer = (x: string, variableId: string) =>
    testValues[variableId] || x;

  // Recursively replace any variables in the request body.
  // This is needed because the request body can be a nested object and we don't know the structure.
  const replaceVariablesInObject = (obj: JSONData): JSONData => {
    if (typeof obj === "string") {
      return obj.replace(variableRegex, replacer);
    }

    // If obj is an array, recursively replace variables in each element
    if (Array.isArray(obj)) {
      return obj.map(replaceVariablesInObject);
    }

    // If obj is another object, recursively replace variables in each key and value
    if (typeof obj === "object" && obj !== null) {
      return Object.fromEntries(
        Object.entries(obj).map(([key, value]) => [
          key,
          replaceVariablesInObject(value),
        ]),
      );
    }

    // If obj is some other type, return it as is
    return obj;
  };

  const jsonRequestBody = requestBody ? JSON.parse(requestBody) : null;
  const replacedRequestBody = replaceVariablesInObject(jsonRequestBody);

  return {
    ...webAction,
    url: url.replace(variableRegex, replacer),
    headers: headers.map((header) => ({
      ...header,
      value: header.value.replace(variableRegex, replacer),
    })),
    request_body: replacedRequestBody
      ? JSON.stringify(replacedRequestBody)
      : "",
  };
};

export const testWebActionApi = async (
  webAction: WebAction | Omit<WebAction, "_id">,
  testValues: {
    inferred_variable?: Record<string, string>;
    variable?: Record<string, string>;
  },
) => {
  const response = await adaAPI.request<{
    step_outcome:
      | {
          // Internal error
          status: "failed";
          return_values: null;
          error_message: string;
        }
      | {
          // API returned an error response
          status: "failed";
          status_code: number;
          return_values: null;
          error_message: string;
          full_response: JSONData;
        }
      | {
          // API returned a success response
          status: "succeeded";
          status_code: number;
          return_values: Record<string, string> | Record<string, string>[];
          full_response: JSONData;
        };
  }>({
    method: "POST",
    url: "/generative_actions/web_actions/test",
    data: {
      web_action: replaceVariablesWithValues(
        webAction,
        testValues.variable || {},
      ),
      test_inputs: testValues.inferred_variable,
    },
  });

  return response.data;
};

export const getRedactedTokenValueForVariable = async (
  variableIds: string[],
) => {
  const response = await adaAPI.request<{
    redacted_values: {
      [token: string]: string;
    };
  }>({
    method: "GET",
    url: "/client_secrets/redacted",
    params: { variable_ids: variableIds },
    paramSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  });

  return response.data.redacted_values;
};

export const isInferredVariableReferenced = (
  webAction: Omit<WebAction, "_id">,
  inferredVariableName: string,
) =>
  webAction.url.includes(`{{${inferredVariableName}}}`) ||
  webAction.headers.some((header) =>
    header.value.includes(`{{${inferredVariableName}}}`),
  ) ||
  webAction.request_body.includes(`{{${inferredVariableName}}}`);

export const transformRequestVariables = (requestBody: string): string => {
  // the idea here is to convert non string variables to strings
  // in order to check if the request body is a valid JSON.
  // e.g. { 'order_number': {{order_number}} } is not a valid JSON
  // { "order_number": "{{order_number}}" } is a valid JSON
  let requestBodyWithVariablesAsStrings = requestBody.replace(
    /(?<!"){{([^{}]+)}}(?!\s*.*")/g,
    '"{{$1}}"',
  );

  // this is for normal variables
  requestBodyWithVariablesAsStrings = requestBodyWithVariablesAsStrings.replace(
    /(?<!"){([^{}]+)\|}(?!\s*.*")/g,
    '"{$1|}"',
  );

  return requestBodyWithVariablesAsStrings;
};
