import { createApi } from "@reduxjs/toolkit/query/react";

import { adaAPI } from "services/api";
import { type GeneratedTopicsV2 } from "slices/generatedTopics/generatedTopicsV2Api";

export interface ReasoningLog {
  type: "reasoning_log";
  client_id: string;
  conversation_id: string;
  created_at: string;
  direction: string;
  event_type: string;
  message: string;
  step_type?: string;
  step_id: string;
  name?: string;
  context?: {
    guidance: {
      id: string;
      text: string;
      title: string;
      rules:
        | {
            id: string;
            rules: string;
          }
        | undefined;
    }[];
    steps: {
      arguments: {
        name: string;
        is_required: boolean;
      }[];
      description: string;
      name: string;
      type: string;
    }[];

    /** @deprecated */
    coaching: {
      id: string;
      coaching: string;
      intent: string;
    }[];
  };
  args?: {
    query: string;
    [key: string]: string;
  };
  outcome?: {
    status: "succeeded" | "failed";
    error_message: string | null;
    return_values: { [k: string]: string } | { [k: string]: string }[] | null;
  };
  metadata?: {
    llm_calls?: {
      module_id?: string;
      returned_coaching?: Array<{
        id: string;
        intent: string;
        instructions: string;
      }>;
      used_coaching_ids?: string[];
    }[];
  };
  _id: string;
  rules: {
    id: string;
    rules: string;
  } | null;
  generated_topic?: GeneratedTopicsV2;
}

export type ReasoningLogResponse = ReasoningLog[];

export const ReasoningLogApi = createApi({
  reducerPath: "reasoningLog",
  baseQuery: (props) => adaAPI.request({ ...props }),
  tagTypes: ["ReasoningLog"],
  endpoints: (builder) => ({
    getReasoningLog: builder.query({
      query: (id: string) => ({
        url: `conversations/generative-events/${id}`,
      }),
      transformResponse: (response: { data: ReasoningLogResponse }) =>
        response.data,
    }),
  }),
});

export const { useGetReasoningLogQuery } = ReasoningLogApi;
