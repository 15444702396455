import { primitives } from "@adasupport/byron";
import styled, { css } from "styled-components";

import { cssVariables } from "constants/css";

const noFlex = css`
  flex-shrink: 0;
  flex-grow: 0;
`;

export const VSpace1 = styled.div`
  height: ${primitives.spacing.space1};
  ${noFlex};
`;
export const VSpace2 = styled.div`
  height: ${primitives.spacing.space2};
  ${noFlex};
`;
export const VSpace3 = styled.div`
  height: ${primitives.spacing.space3};
  ${noFlex};
`;
export const VSpace4 = styled.div`
  height: ${primitives.spacing.space4};
  ${noFlex};
`;
export const VSpace5 = styled.div`
  height: ${primitives.spacing.space5};
  ${noFlex};
`;
export const VSpace6 = styled.div`
  height: ${primitives.spacing.space6};
  ${noFlex};
`;
export const VSpace7 = styled.div`
  height: ${primitives.spacing.space7};
  ${noFlex};
`;
export const VSpace8 = styled.div`
  height: ${primitives.spacing.space8};
  ${noFlex};
`;
export const VSpace9 = styled.div`
  height: ${primitives.spacing.space9};
  ${noFlex};
`;
export const VSpace10 = styled.div`
  height: ${primitives.spacing.space10};
  ${noFlex};
`;
export const VSpace12 = styled.div`
  height: ${primitives.spacing.space12};
  ${noFlex};
`;
export const VSpace14 = styled.div`
  height: ${primitives.spacing.space14};
  ${noFlex};
`;

export const HSpace1 = styled.span`
  display: inline-block;
  width: ${primitives.spacing.space1};
  ${noFlex};
`;

export const HSpace2 = styled.span`
  display: inline-block;
  width: ${primitives.spacing.space2};
  ${noFlex};
`;
export const HSpace3 = styled.span`
  display: inline-block;
  width: ${primitives.spacing.space3};
  ${noFlex};
`;
export const HSpace4 = styled.span`
  display: inline-block;
  width: ${primitives.spacing.space4};
  ${noFlex};
`;
export const HSpace5 = styled.span`
  display: inline-block;
  width: ${primitives.spacing.space5};
  ${noFlex};
`;
export const HSpace6 = styled.span`
  display: inline-block;
  width: ${primitives.spacing.space6};
  ${noFlex};
`;
export const HSpace7 = styled.span`
  display: inline-block;
  width: ${primitives.spacing.space7};
  ${noFlex};
`;
export const HSpace8 = styled.span`
  display: inline-block;
  width: ${primitives.spacing.space8};
  ${noFlex};
`;
export const HSpace12 = styled.span`
  display: inline-block;
  width: ${primitives.spacing.space12};
  ${noFlex};
`;

export const Space4 = styled.div`
  height: ${cssVariables.space4};
`;

export const Space5 = styled.div`
  height: ${cssVariables.space5};
`;

export const Space6 = styled.div`
  height: ${cssVariables.space6};
`;

export const Space7 = styled.div`
  height: ${cssVariables.space7};
`;

export const Space14 = styled.div`
  height: ${primitives.spacing.space14};
`;
