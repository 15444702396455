import { primitives } from "@adasupport/byron";
import styled from "styled-components";

export const Flex = styled.div<{
  justifyContent?: "flex-start" | "flex-end" | "center" | "space-between";
  alignItems?: "flex-start" | "flex-end" | "center";
  gap?: boolean | keyof typeof primitives.spacing;
  stretch?: boolean;
}>`
  display: flex;
  justify-content: ${(p) => p.justifyContent || "flex-start"};
  align-items: ${(p) => p.alignItems || "flex-start"};
  width: ${(p) => (p.stretch ? "100%" : "auto")};

  gap: ${(p) => {
    if (!p.gap) return "0";
    if (typeof p.gap === "boolean") return primitives.spacing.space2;

    return primitives.spacing[p.gap];
  }};
`;

export const FlexV = styled.div<{
  alignItems?: "flex-start" | "flex-end" | "center";
  gap?: boolean;
  stretch?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: ${(p) => p.alignItems || "normal"};
  gap: ${(p) => (p.gap ? "8px" : "0")};
  width: ${(p) => (p.stretch ? "100%" : "auto")};
`;

export const FlexColumn = styled.div<{
  grow?: number;
  shrink?: number;
  noBasis?: boolean;
  flex?: boolean;
  hidden?: boolean;
  alignSelf?: "flex-start" | "flex-end" | "center" | "stretch";
}>`
  min-width: 0;
  flex-shrink: ${(p) => p.shrink || 0};
  flex-basis: ${(p) => (p.noBasis ? "0" : "auto")};
  flex-grow: ${(p) => p.grow || 0};
  align-self: ${(p) => p.alignSelf || "stretch"};
  display: ${(p) => (p.flex ? "flex" : "block")};
  ${(p) => p.hidden && "visibility: hidden;"}
`;
