import { Icon, primitives, tokens } from "@adasupport/byron";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { type MouseEventHandler } from "react";
import styled, { css } from "styled-components";

import { type AutoCaptureInferenceMode } from "components/Common/Slate";
import SvgIcon from "components/Common/SvgIcon";

const getMetaVariableStyles = () => css`
  background-color: ${primitives.palette.plum200};
  color: ${primitives.palette.plum700};
  border: 1px solid ${primitives.palette.plum200};
  outline: 0 solid ${primitives.palette.plum700};
`;

const getInferredVariableStyles = ({ isOutput }: { isOutput?: boolean }) => css`
  background-color: ${isOutput
    ? primitives.palette.blue200
    : tokens.colors.background.main};
  color: ${primitives.palette.blue600};
  outline: 0 solid ${primitives.palette.blue300};

  ${!isOutput &&
  css`
    border: 1px solid ${primitives.palette.blue300};
  `}
`;

const getTokenVariableStyles = () => css`
  background-color: ${primitives.palette.gold200};
  color: ${primitives.palette.gold900};
  border: 1px solid ${primitives.palette.gold200};
  outline: 0 solid ${primitives.palette.gold900};
`;

const getGlobalVariableStyles = () => css`
  background-color: ${primitives.palette.green200};
  color: ${primitives.palette.green800};
  border: 1px solid ${primitives.palette.green200};
  outline: 0 solid ${primitives.palette.green900};
`;

const getLocalVariableStyles = () => css`
  background-color: ${primitives.palette.blue200};
  color: ${primitives.palette.blue800};
  border: 1px solid ${primitives.palette.blue200};
  outline: 0 solid ${primitives.palette.blue900};
`;

const getOrphanedVariableStyles = () => css`
  color: ${primitives.palette.red600};
  outline: 0 solid ${primitives.palette.red600};
  border: 1px solid ${primitives.palette.red400};
`;

const getUnknownVariableStyles = () => css`
  background-color: ${primitives.palette.slate200};
  color: ${primitives.palette.slate500};
  outline: 0 solid ${primitives.palette.slate400};
  border: 1px solid ${primitives.palette.slate200};
`;

const S = {
  VariablePill: styled.span<{
    type:
      | "meta"
      | "token"
      | "inferred"
      | "global"
      | "local"
      | "auto_capture"
      | "orphaned"
      | "unknown";
    isSelected: boolean;
    onClick?: unknown;
    isOutput?: boolean;
  }>`
    position: relative;
    display: inline-flex;
    height: 20px;
    align-items: center;
    border-radius: 10px;
    font-family: Menlo, Monaco, Consolas, "Lucida Console", monospace;
    font-weight: 700;
    font-size: 12px;
    padding: 0 8px 0 22px;
    white-space: nowrap;
    max-width: 100%;

    ${(p) => p.type === "meta" && getMetaVariableStyles()}
    ${(p) => p.type === "inferred" && getInferredVariableStyles(p)}
    ${(p) => p.type === "auto_capture" && getInferredVariableStyles(p)}
    ${(p) => p.type === "token" && getTokenVariableStyles()}
    ${(p) => p.type === "global" && getGlobalVariableStyles()}
    ${(p) => p.type === "local" && getLocalVariableStyles()}
    ${(p) => p.type === "orphaned" && getOrphanedVariableStyles()}
    ${(p) => p.type === "unknown" && getUnknownVariableStyles()}
    
    &:focus, &:active {
      outline-width: 2px;
      z-index: 1;
    }

    ${(p) =>
      p.isSelected &&
      css`
        outline-width: 2px;
        z-index: 1;
      `}

    ${(props) =>
      props.onClick &&
      css`
        cursor: pointer;
      `}
  `,

  Icon: styled.span`
    position: absolute;
    height: 16px;
    left: 4px;
    top: 50%;
    transform: translateY(-50%);
  `,

  DropdownIcon: styled.span`
    display: inline-block;
    margin-left: 4px;
  `,

  Name: styled.span`
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  `,

  InferenceMode: styled.span`
    background: ${primitives.palette.blue100};
    padding: 2px 4px;
    margin-left: 5px;
    margin-right: 3px;
  `,
};

export const VariablePill = ({
  name,
  type,
  valueType,
  isSelected = false,
  onClick,
  hasDropdownIcon = false,
  isInferredOutputVariable = false,
  inferenceMode = null,
}: {
  name: string;
  type:
    | "meta"
    | "token"
    | "inferred"
    | "auto_capture"
    | "global"
    | "local"
    | "orphaned"
    | "unknown";
  valueType: "text" | "number" | "boolean" | "list" | "unknown";
  isSelected?: boolean;
  onClick?: MouseEventHandler<HTMLSpanElement>;
  hasDropdownIcon?: boolean;
  isInferredOutputVariable?: boolean;
  inferenceMode?: AutoCaptureInferenceMode | null;
}) => {
  const flags = useFlags();
  let TypeIcon = {
    text: Icon.Text,
    number: Icon.Number,
    boolean: Icon.IsFalse,
    list: Icon.List,
    unknown: Icon.Variable,
  }[valueType];

  if (type === "meta") {
    TypeIcon = Icon.Variable;
  } else if (type === "token") {
    TypeIcon = Icon.Hide;
  } else if (type === "orphaned") {
    TypeIcon = Icon.Exclamation;
  }

  return (
    <S.VariablePill
      as={onClick ? "button" : "span"}
      type={type}
      isSelected={isSelected}
      isOutput={isInferredOutputVariable}
      onClick={onClick}
    >
      <S.Icon>
        <TypeIcon
          height={16}
          width={16}
          color={
            {
              meta: primitives.palette.plum500,
              token: primitives.palette.gold600,
              inferred: primitives.palette.blue300,
              auto_capture: primitives.palette.blue300,
              global: primitives.palette.green500,
              local: primitives.palette.blue400,
              orphaned: primitives.palette.red400,
              unknown: primitives.palette.slate400,
            }[type]
          }
        />
      </S.Icon>
      <S.Name>{name}</S.Name>
      {flags.train_autocapture_always_ask && inferenceMode && (
        <S.InferenceMode>{inferenceMode}</S.InferenceMode>
      )}

      {hasDropdownIcon && (
        <S.DropdownIcon>
          <SvgIcon icon="Variables/Icons/ArrowDown" height={8} />
        </S.DropdownIcon>
      )}
    </S.VariablePill>
  );
};
