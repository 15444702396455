import Immutable from "immutable";

import { type ExpressionActions } from "actions/expressions/types";
import { type QuestionsPageMapAttributes } from "components/Declarative/Pages/Questions/types";
import { type TypedMap } from "types";

const defaultQuestionsPageMap: QuestionsPageMapAttributes = {
  pageSize: 25,
  searchQuery: "",
  checked: new Set(),
  checkedAll: false,
  checkedDeep: false,
  elementIndex: null,
  originFilter: "",
  viewingTranslations: false,
  mouseSelection: null,
  mouseSelectionOffset: null,
};

export const questionsPageMap = Immutable.Map(
  defaultQuestionsPageMap,
) as TypedMap<QuestionsPageMapAttributes>;

export const questionsPage = (
  state = questionsPageMap,
  action: ExpressionActions,
) => {
  switch (action.type) {
    case "SET_EXPRESSIONS_LIST":
      return state.merge(action.payload);

    case "TRAIN_EXPRESSION_SUCCESS":
    case "PATCH_EXPRESSION_SUCCESS":
    case "MOVE_EXPRESSION_SUCCESS":
    case "TRAIN_EXPRESSIONS_SUCCESS":
    case "DELETE_UNTRAINED_EXPRESSIONS_SUCCESS":
    case "DELETE_EXPRESSION_SUCCESS":
      return state.merge({
        checked: new Set(),
        checkedAll: false,
        checkedDeep: false,
        mouseSelection: null,
        mouseSelectionOffset: null,
        elementIndex: null,
      });

    case "GET_EXPRESSIONS_SUCCESS":
      return state.merge({
        originFilter: action.origin,
      });

    case "TOGGLE_VIEWING_TRANSLATIONS":
      return state.merge({
        viewingTranslations: !state.get("viewingTranslations"),
      });

    default:
      return state;
  }
};
