import { Button, Icon, Select, TextInput } from "@adasupport/byron";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { createUnsavedVariable } from "actions/variables";
import { IconWithLabel } from "components/Common/ByronSelectUtilities";
import {
  CaptureModeSelector,
  CaptureModeSelectorLabel,
} from "components/Common/CaptureModeSelector";
import { VariableRecord } from "reducers/variables/helpers";
import { useAlert } from "services/alert";
import { useVariables } from "services/variables";
import { type CaptureMode } from "services/webActions";

import { Flex } from "../Flex";
import { InputHint } from "../InputHint";
import { InputLabel } from "../InputLabel";
import { VSpace4 } from "../Space";

const DATA_TYPE_OPTIONS = [
  {
    value: "string",
    label: <IconWithLabel text="String" icon={Icon.Text} />,
  },
  {
    value: "long",
    label: <IconWithLabel text="Number" icon={Icon.Number} />,
  },
  {
    value: "bool",
    label: <IconWithLabel text="Boolean" icon={Icon.IsFalse} />,
  },
] as const;

export const VariablePalette = ({
  onCreate = () => undefined,
  allowedScopes,
  defaultAttributes,
}: {
  onCreate?: (variableId: string) => void;
  allowedScopes?: ("auto_capture" | "global")[];
  defaultAttributes?: Partial<{
    name: string;
    description: string;
    _type: "string" | "long" | "bool";
  }>;
}) => {
  const flags = useFlags();
  const initialAttributes = {
    scope: allowedScopes?.includes("auto_capture") ? "auto_capture" : "global",
    name: "",
    description: "",
    capture_modes: ["conversation"] as CaptureMode[],
    _type: "string",
    ...defaultAttributes,
  } as const;

  const [attributes, setNewVariableAttributes] = useState(initialAttributes);

  const [isCreating, setIsCreating] = useState(false);
  const [didAttemptCreate, setDidAttemptCreate] = useState(false);
  const { createVariable } = useVariables();
  const dispatch = useDispatch();
  const { createAlert } = useAlert();
  const inputContainerRef = React.useRef<HTMLDivElement | null>(null);

  const invalidFields = ((fields) => {
    if (!attributes.name) {
      fields.add("name");
    }

    if (attributes.scope === "auto_capture" && !attributes.description) {
      fields.add("description");
    }

    return fields;
  })(new Set<"name" | "description">());

  useEffect(() => {
    if (inputContainerRef.current) {
      inputContainerRef.current.querySelector("input")?.focus();
    }
  }, []);

  return (
    <>
      <InputLabel label="Variable type" />
      <Select
        isDisabled={allowedScopes?.length === 1}
        options={(
          [
            { value: "auto_capture", label: "Autocapture" },
            { value: "global", label: "Global" },
          ] as const
        ).filter((option) =>
          allowedScopes ? allowedScopes.includes(option.value) : true,
        )}
        value={attributes.scope}
        onChange={(v: "global" | "auto_capture") =>
          setNewVariableAttributes({ ...attributes, scope: v })
        }
      />

      <VSpace4 />

      <InputLabel label="Name" />
      <div ref={inputContainerRef}>
        <TextInput
          placeholder="Enter variable name"
          value={attributes.name}
          onChange={(v) => setNewVariableAttributes({ ...attributes, name: v })}
          isInvalid={didAttemptCreate && invalidFields.has("name")}
        />
        {didAttemptCreate && invalidFields.has("name") && (
          <InputHint type="error">Required</InputHint>
        )}
      </div>

      {attributes.scope === "auto_capture" && (
        <>
          <VSpace4 />
          <InputLabel label="Description" />
          <TextInput
            placeholder="Enter description"
            value={attributes.description}
            onChange={(v) =>
              setNewVariableAttributes({ ...attributes, description: v })
            }
            isInvalid={didAttemptCreate && invalidFields.has("description")}
          />
          {didAttemptCreate && invalidFields.has("description") ? (
            <InputHint type="error">Required</InputHint>
          ) : (
            <InputHint>Tell AI agent what to capture</InputHint>
          )}
          {flags.voice_auto_capture_variables && (
            <>
              <VSpace4 />
              <CaptureModeSelectorLabel />
              <CaptureModeSelector
                selectedCaptureModes={attributes.capture_modes}
                dataType="text" // For now, all auto-capture variables are strings
                onChange={(v) =>
                  setNewVariableAttributes({ ...attributes, capture_modes: v })
                }
                hideDTMF
              />
            </>
          )}
        </>
      )}

      {attributes.scope === "global" && (
        <>
          <VSpace4 />
          <InputLabel label="Data type" />
          <Select
            options={DATA_TYPE_OPTIONS}
            value={attributes._type}
            onChange={(v: "string" | "long" | "bool") =>
              setNewVariableAttributes({ ...attributes, _type: v })
            }
          />
        </>
      )}

      <VSpace4 />
      <Flex justifyContent="flex-end">
        <Button
          text="Create variable"
          isLoading={isCreating}
          isDisabled={didAttemptCreate && invalidFields.size > 0}
          onClick={async () => {
            setDidAttemptCreate(true);

            if (invalidFields.size > 0) {
              return;
            }

            setIsCreating(true);

            try {
              const result = await createVariable(
                attributes.scope === "auto_capture"
                  ? {
                      ...attributes,
                      _type: "string",
                      scope: "auto_capture",
                    }
                  : attributes,
              );

              if ("data" in result && "_id" in result.data) {
                createAlert({
                  alertType: "success",
                  message: "Variable created successfully",
                });

                // Update the legacy redux state so the variable can be accessed by other blocks right away
                const legacyVar = new VariableRecord({
                  id: result.data._id,
                  name: attributes.name,
                  scope: attributes.scope,
                });
                dispatch(
                  createUnsavedVariable({
                    variable: legacyVar,
                    responseId: null,
                  }),
                );

                onCreate(result.data._id);
              } else {
                throw new Error("Failed to create variable");
              }
            } catch {
              createAlert({
                alertType: "error",
                message: "Failed to create variable",
              });
            } finally {
              setIsCreating(false);
              setDidAttemptCreate(false);

              // Reset form
              setNewVariableAttributes(initialAttributes);
            }
          }}
        />
      </Flex>
    </>
  );
};
